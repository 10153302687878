.icon {
  transition: transform .7s ease-in-out;
  pointer-events: auto;
  padding: 6px;
  filter: drop-shadow(0em 0em .3em rgba(255, 255, 255, 0.7));
  width: 3.5em;
}

.iconorange {
  transition: transform .7s ease-in-out;
  pointer-events: auto;
  padding: 6px;
  filter: drop-shadow(0em 0em .3em #ED7E48);
  width: 3.5em;
}

.iconorange:hover {
  transform: rotate(360deg) scale(.9);
  cursor: pointer;
}

.icon:hover {
  transform: rotate(360deg) scale(.9);
  cursor: pointer;
}

html {
  margin: 0;
  padding: 0;
  max-height: 100vh;
  min-height: 100vh;
  height:100vh;
  max-width: 100vw;
  position:fixed;
  overflow: hidden;
}

h1 {
  margin: 0;
  padding: 0;
}

body {
  min-height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
  position:fixed;
}

canvas {
  width: 100% !important;
  height: 100% !important;
  object-fit: contain;
}

#particles canvas{
  position: absolute;
  width: 100%;
  height: 100%;
}

.centerdock { 
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  min-height: 100vh;
  transform: translate(0%, -8%);
  align-items: center;
  text-align: center;
  margin: 0 auto;
  width: 80%;
  pointer-events: none;
}

.img{
  font-family: 'Nunito';
  font-size: 1.5em;
  font-weight: bold;
  font-style: italic;
  color: white;
  text-shadow: 0em 0em .3em #ffffff;
}

#logo {
  margin: 0 auto;
  padding: 12px;
  width: 100%;
  pointer-events: none; 
}

#quote {
  font-family: 'Nunito';
  font-size: 1.5em;
  font-weight: bold;
  font-style: italic;
  color: white;
  text-shadow: 0em 0em .3em #ffffff;
  width:100%;
  pointer-events: auto;
  transform: translate(0%, -.3em);
  cursor:pointer;
}

#buttons {
  flex: 1 1 33%
}

.app{
  pointer-events: none;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li{
  display:inline-block;
}

.container hr {
  width: 100%;
  content: '';
  height: 0; 
  margin: 0; 
  border: 0; 
}

/* ul{
  text-align: justify;
}

ul::after {
  content: '';
  width: 100%; 
  display: inline-block;
}

 */